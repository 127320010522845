@import '~@angular/material/theming';
@include mat-core();

$bwr-primary: mat-palette($mat-blue, 900);
$bwr-accent:  mat-palette($mat-grey);

$bwr-warn: mat-palette($mat-red, 900);

$bwr-theme: mat-light-theme($bwr-primary, $bwr-accent, $bwr-warn);


$custom-typography: mat-typography-config(
  $font-family: 'Open Sans',
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(16px, 24px, 500)
);
@include angular-material-typography($custom-typography);
@include mat-core($custom-typography);
@include angular-material-theme($bwr-theme);

body {
  margin: 0px !important;
}
@import "~@fortawesome/fontawesome-free/css/all.min.css";

h1, h2, h3, h4, h5 {
  color: #01579b;
  font-weight: bolder;
}

.background {
  padding: 1rem;
  height: 100vh!important;
}

.background.color{
  background-color:rgba(228, 232, 235, 0.5);
}
